import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Felaktig sökväg</h1>
    <p>Kunde tyvärr inte hitta sidan du letar efter</p>
  </Layout>
)

export default NotFoundPage
